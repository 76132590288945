@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Black.ttf') format('truetype');
	font-weight: 900; /* Black */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Book.ttf') format('truetype');
	font-weight: 400; /* Book/Regular */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Heavy.ttf') format('truetype');
	font-weight: 700; /* Heavy/Bold */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Light.ttf') format('truetype');
	font-weight: 300; /* Light */
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../Avenir/Avenir-Regular.ttf') format('truetype');
	font-weight: 500; /* Regular/Medium */
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.dropdown-wrapper {
	position: relative;
}

.dropdown-icon {
	cursor: pointer;
	background-image: url('dropdown-icon.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 25px;
	height: 25px;
	display: inline-block;
	transition: box-shadow 0.1s ease-in-out; /* Added transition for smooth effect */
}

.dropdown-icon:hover {
	box-shadow: 0 0 8px rgba(211, 148, 247, 0.913); /* Glow effect */
}

.dropdown-menu {
	display: none;
	position: absolute;
	right: 4px;
	top:calc(100% - 5px);
	background-color: #fff;
	border: 1px solid #ccc;
	z-index: 1;
}



/* Distinct styles for the new WorkHub link */
.workhub-wrapper {
	display: flex;
	align-items: center;
	gap: 5px; /* Space between icon and link */
	border: 2px solid #8118ab; /* Green border to make it stand out */
	padding: 5px;
	border-radius: 5px;
}

.workhub-icon {
	width: 20px;
	height: 20px;
	background-color: #7b85fc;
	background-image: url('workhub-icon.png');
	background-size: cover;
	background-position: center;
	border-radius: 50%; /* Make it circular */
	display: inline-block;
}

.nav-link-special a {
	color: #8118ab; /* Green text to match the icon */
	text-decoration: none;
	font-weight: bold;
	transition: color 0.3s;
	font-family: 'Comfortaa', sans-serif;
}

.nav-link-special a:hover {
	color: #c832cb; /* Darken the green on hover */
}


.dashboard-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	padding: 20px 0;
	background-color: #fff;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
	flex-wrap: wrap;
}



/* Media Query for smaller screens */
@media (max-width: 768px) {
	.dashboard-header {
		flex-direction: column; /* Stack the header items vertically */
		text-align: center; /* Center-align text */
		align-items: center; /* Center-align flex items */
	}

	.left-header {
			flex-wrap: wrap; /* Allow items to wrap if they don't fit */
			justify-content: center; /* Center items horizontally */
		}
	
	.logo {
		width: 35px;  /* Smaller width */
		height: 35px;  /* Smaller height */
		margin-right: 8px; /* Smaller margin to the right */
		margin-left: 10px;
	}

	.noktHeader {
		font-size: 16px;  /* Further reduce the font size */
		margin: 0; /* Eliminate any potential margin */
	}

	.nav-links {
		width: 100%; /* Take the full width of the parent container */
		justify-content: center; /* Center the navigation links horizontally */
		margin-top: 10px; /* Space out the navigation links from the title */
	}

}


.left-header {
	display: flex;
	align-items: center;
}

.logo {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	margin-left: 20px;
	
}

.noktHeader {
	font-size: 28px;
	margin: 0;
	color: #2e2f32;
	flex: 0 0 auto;
}

.nav-links {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-right: 40px;
	font-size: 16px;
	z-index: 0; /* Make sure dropdown appears on top */
	font-weight: bold;
	font-family: 'Comfortaa', sans-serif;
}

.nav-link {
	cursor: pointer;
	transition: color 0.3s;
	
}

.nav-link:hover {
	color: #a2a3e9;
}

.filters-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 1px 0px 5px rgba(0,0,0,0.05);
	background-color: #e8e9ed;
	margin-right: 10px; 
	margin-left: 10px;
}

.filter-title {
	margin-left:3px;
	margin-bottom: 5px;
	font-weight: 600;
	text-align: left;
	color: #2e2f32;
}

.filter {
	flex: 1;
	margin: 0 10px;
}

.filter select {
	width: 100%;
	padding: 10px;
	font-size: 14px;
	border: 1px solid #a2a3e9;
	border-radius: 4px;
	background-color: #fff;
}

.alert-list {
	list-style-type: none;
	padding: 0;
	background-color: #fff;
	border-radius: 5px;
}

.alert-list-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	background-color: #e8e9ed; /* A light gray background for differentiation */
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-weight: 600; /* Making the font a bit bold */
	margin-bottom: 5px; /* A small margin to separate from the list */
	margin-left:10px;
	margin-right: 10px;
	cursor: pointer;
}

.export-images-button {
  /* Add your styling here */
  margin-left: 10px;
  width: 25%
}


.alert-item {
	padding: 15px;
	border-bottom: 1px solid #e1e1e1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left:7px;
	margin-right: 7px;
	cursor: pointer;
}

.alert-item:last-child {
	border-bottom: none;
}

.alert-item:hover {
	background-color: #e6e6e6;
}

.alert-content {
	flex: 2;
	padding:5px;
	margin-right: 50px;
	text-align: justify;
}

.alert-agent{	
	flex: 0.5;
	margin-left: 15px;
}

.alert-time, .alert-criticality {
	flex: 1;
}


	
.alert-criticality {
	text-transform: uppercase;
	border-radius: 3px;
	padding: 5px;
	text-align: center;
}

.alert-criticality.low {
	background-color:  #b9bdf6;
	color: white;
	font-weight: bold;
}

.alert-criticality.medium {
	background-color:  #7b85fc;
	color: white;
	font-weight: bold;
}

.alert-criticality.high {
	background-color: #8118ab;
	color: white;
	font-weight: bold;
}


.sort-indicator {
	margin-left: 5px;
}

.asc::after {
	content: "↑";
}

.desc::after {
	content: "↓";
}



/* ... other styles remain unchanged ... */

.report-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
  color: #333;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-bottom: 15px;
  display:flex;
  flex-direction: column;
}

.report-container p {
  text-align: justify;
  margin: 10px 0; /* Adjust margins as necessary */
}


/* Style for the report header container */
.report-header {
  display: flex;
  align-items: center; /* Align the title and logo vertically */
  justify-content: space-between; /* Space between title and logo */
  background-color: rgb(247,248,254);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 10px;
}

/* Style for the report title */
.report-title {
  margin: 0; 
}

/* Style for the logo image */
.report-logo {
  height: 30px; /* You can adjust the size as needed */
  width: auto; /* This will maintain the aspect ratio of the logo */
  /* If you need to adjust the alignment or add padding, you can do so here */
}


.chat-panel {
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.chat-panel {
	background: #e5ddd5; /* Background color similar to WhatsApp light theme */
	padding: 30px;
	padding-top:50px;
	max-width: 60vw;
	max-height: 80vh;
	overflow-y: auto;
	font-family: 'Avenir';
	border-radius: 15px;
	align-self:center;
}


.chat-page {
	background: rgba(0, 0, 0, 0.6);
display: flex;
justify-content: center;
align-items: center;
width:100%;
height:100vh
}

.chat-container {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	max-height: 70vh;
	min-height: 50vh;
	min-width: 50vw;
}
.chat-box {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	max-height: 80vh;
	min-height: 50vh;
	min-width: 50vw;
	
}


.chat-message {
	padding: 10px 15px;
	margin: 10px 0;
	border-radius: 15px;
	max-width: 70%;
	word-wrap: break-word;
	align-self: flex-start; /* Default alignment for incoming messages */
	
	
}

.export-chat-history-button {
  position: sticky; /* Position the button inside its parent */
  width: 20%; /* Set width to 20% */
  background-color: color(display-p3 0.463 0.482 0.835); /* Optional: Button background color */
  color: white; /* Optional: Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  align-self: flex-end;
}


.chat-message.left {
	align-self: flex-start;
	background-color: #f0f0f0;
	color: #2e2f32;

}


.chat-message.right {
	align-self: flex-end;
	background-color: #dcf8c6;
	color: #2e2f32;

}

.chat-user {
	font-weight: bold;
	margin-bottom: 5px;
}

.chat-body {
	margin-top: 5px;
}

.chat-timestamp {
	font-size: 0.8em;
	color: gray;
	text-align: right;
	margin-top: 5px;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #333; /* You can adjust this color if needed */
}
	
.close-button:hover {
	background-color: #a2a3e9;
}

/* Styling for the button container */
.button-container {
	font-family: 'Avenir';
	display: flex; 
	justify-content: flex-end; /* Align the buttons to the right */
	gap: 10px; /* Minimal space between the buttons */
	margin-top: 15px; /* Space from the chat container or any other element above */
}




/* Styling for the copy room id button */
.copy-roomid-button {
	color: #fff; /* Blueish-purple to blend in */
}

.copy-roomid-button:hover {
	background-color: rgba(255, 255, 255, 0.65);
	color: purple; /* A very light blueish-purple background on hover */
}

/* Styling for the see full history button */
.see-full-history-button {
	color: #fff; /* Blueish-purple to blend in */
}

.see-full-history-button:hover {
	background-color: rgba(255, 255, 255, 0.65);
	color: purple; /* A very light blueish-purple background on hover */
}

.copy-roomid-button.greened-out {
	color: #fff; 
	background-color: #b2e6c5; /* A shade of green */
	pointer-events: none; /* Disable any further interactions */
}



.analyze-chat-history-button {
	position: absolute; /* Position the button absolutely within its relative parent */
	top: 10px; /* Adjust as needed to position from the top */
	left: 10px; /* Adjust as needed to position from the left */
	z-index: 10; /* Ensure it's above other content if needed */
	transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
	width: 60px; /* Example width, adjust as needed */
	height: 30px; /* Example height, adjust as needed */
	padding: 4px 8px; /* Reduced padding */
	font-size: 12px; /* Reduced font size */
	text-align: center; /* Center the text inside the button */
	line-height: 22px; /* Adjust line height to vertically center text, depends on button height */
	overflow: hidden; /* Prevents content from spilling outside */
	white-space: nowrap; /* Keeps the text on one line */
	border-radius: 15px; /* Adds rounded corners for a softer, more button-like appearance */
}

.analyze-chat-history-button:hover {
	background-color: #007bff; /* Example hover background color (blue) */
	color: #ffffff; /* Example text color on hover (white) */
	box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Example shadow for depth effect */
	cursor: pointer; /* Change cursor to indicate it's clickable */
}








.analyze-modal-overlay,
.analyze-modal-content,
.modal-split,
.chat-analysis-container,
.side-panel,
.sidechat-container,
.input-container,
.input-container input,
.input-container button {
	box-sizing: border-box;
}



.analyze-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #333; /* You can adjust this color if needed */
}
	
.analyze-close-button:hover {
	background-color: #a2a3e9;
}


/* For the AnalyzeChatHistoryModal overlay */
.analyze-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.7);
	width: 100vw;
	height: 100vh;
	z-index: 1000;
}

.analyze-modal-content{
	position: relative;
	background: transparent;
	padding: 10px;
	font-family: 'Avenir';
	border-radius: 10px;
	width: 90vw;
	height: 90vh;
	display: flex;
}

.modal-split {
	
	display: flex;
	justify-content: flex-start; 
	height: 100%;
	width: 100%; 
}

.chat-analysis-container {
	font-family: 'Avenir';
	justify-content: space-between;
	flex: 1;
	overflow-y: scroll;
	background: whitesmoke;
	border-radius: 15px;
	padding: 15px;
	justify-content: flex-end;
	display: flex;
	flex-direction: column;
}

.side-panel {
	flex: 0 0 30%; 
	background-color: #e6e6e6; /* Slightly darker grey for distinction */
	border-right: 1px solid #ccc; /* Light border */
	overflow-y: scroll;
	box-shadow: 2px 0px 10px rgba(0,0,0,0.1); /* Subtle shadow for depth */
	height: 100%;
	border-radius: 15px;
	padding:15px;
}


.sidechat-container {
	height: 90%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}


.input-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	background-color: #fff; /* match your input background */
	border: 1px solid #ccc;  /* mimic an input border */
	border-radius: 15px;  /* rounded corners */
	overflow: hidden;  /* ensure content doesn't spill out */
	justify-content: space-between;
	margin-top: 20px; /* Added this */
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);  /* Add shadow */
}

.input-wrapper div[contenteditable="true"] {
	padding: 10px 40px 10px 10px; /* Add padding to the right to make space for the send button */
	font-size: 16px;
	margin: 0;
	background-color: transparent;
	outline: none;
	flex-grow: 1;     /* Allow it to grow */
	flex-shrink: 1;   /* Let it shrink if required */
	border: none;     /* Ensure no border is taking up extra space */
	min-height: 40px;   /* Minimum height of the input field */
	max-height: 150px;  /* Maximum vertical extent to which the input can grow */
	overflow-y: auto;   /* Add scroll when content exceeds max-height */
}


[contentEditable=true]:empty:not(:focus):before {
	content: attr(data-placeholder);
	color: #aaa;  /* Placeholder text color */
}



.send-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
	transition: background-color 0.3s;
	flex: none;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15%;  
	margin-right:5px; 
}

.send-button i {
	color: #c4c4c4;
}

/* When the user has typed something */
.send-button.active {
	background-color: #a2a3e9;
}

.send-button:hover {
	background-color: #b2e6c5;
}



.ongoing-conversation {
	margin-top: 20px;
	overflow-y: scroll;
	background-color: transparent;	
	flex: 1;
	padding-bottom: 20px;
	padding-left: 15px;
	padding-right:15px;
	padding-top:15px;


}

.ongoing-conversation .message {
	padding-bottom: 20px; 
	padding-left: 20px;
	padding-right:20px;
	padding-top:20px;
	min-height: 30wh;
	text-align: justify;
	border-radius: 4px;
	border: 1px solid #c4c4c4;
	display: flex;
	flex-direction: column;
	word-wrap: break-word; 
}

.ongoing-conversation .user{
	align-self: flex-start;
	background-color: #fff;
	color: #333;
}

.ongoing-conversation .assistant {
	align-self: flex-start;
	background-color: #edf4f1;
	color: #2e2f32;
}



.loading-indicator {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid #b2e6c5; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .chat-panel {
	max-width: 100%;
	padding: 10px;
	max-height: 100%;
  }
  
  .chat-page {
	  width: 100%;
	  height: 100%;
	}


  .export-chat-history-button {
	font-size: 12px;
	padding: 10px;
	width: 25%
  }
  .export-images-button {
	  font-size: 12px;
	  padding: 10px;
	  width: 25%
	}
	
  .chat-message {
	padding: 16px;
	max-width: 70%
  }

  .chat-container {
	max-height: 90vh; /* Limit height on mobile to avoid excessive scrolling */
	padding: 5px;
	max-width: 100%;
  }

  .chat-timestamp {
	font-size: 0.7em;
  }
  .export-chat-history-button {
	margin-bottom:10px;  
  }
}

.button-container {
  display: flex;             /* Use flexbox for layout */
  flex-direction: row;       /* Arrange items horizontally */
  justify-content: flex-end; /* Align buttons to the right */
  gap: 10px;                 /* Space between buttons */
  margin-bottom: 15px;       /* Space below the buttons */
  width: 100%;               /* Ensure it spans the full width */
}



